import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { Link } from "gatsby";
import Head from "../components/head";
const CenteredMessage = styled.div`
  text-align: center;
`;

const NotFound = () => {
  return (
    <Layout>
      <Head title="404" />
      <CenteredMessage>
        Sorry.. this page does not exist yet.
        <br />
        <Link to="/">Go home</Link>
        <br />
        🐼🐼🐼
      </CenteredMessage>
    </Layout>
  );
};
export default NotFound;
